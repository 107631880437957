<template>
  <div class="twoPage" ref="qudao">
    <div class="bigTitle demibold animate__animated animate__fadeInUp">
      {{ $t("核心功能") }}
    </div>
    <div class="main animate__animated animate__fadeInUpBig">
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 350px'
            : 'height: 290px'
        "
      >
        <img src="@/assets/imgs/1.png" alt="" />
        <div class="smallTitle demibold animate__animated animate__fadeInUpBig">
          {{ $t("专属服务") }}
        </div>
        <p
          class="content regular animate__animated animate__fadeInUpBig"
          id="content1"
        >
          {{ $t("卡片专属福利服务，出行、商务、旅游、住宿，精彩横生包罗万象") }}
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 350px'
            : 'height: 290px'
        "
      >
        <img src="@/assets/imgs/2.png" alt="" />
        <div class="smallTitle demibold animate__animated animate__fadeInUpBig">
          {{ $t("全球消费") }}
        </div>
        <p
          class="content regular animate__animated animate__fadeInUpBig"
          id="content2"
        >
          {{ $t("撒欢自在，从现在开始，做你想做的事") }}!
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 350px'
            : 'height: 290px'
        "
      >
        <img src="@/assets/imgs/6.png" alt="" />
        <div class="smallTitle demibold">{{ $t("全球提现") }}</div>
        <p
          class="content regular animate__animated animate__fadeInUpBig"
          id="content3"
        >
          {{
            $t("全球275个地区与国家的实体卡自由提现服务。从容面对，安全体验")
          }}
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 350px'
            : 'height: 290px'
        "
      >
        <img src="@/assets/imgs/3.png" alt="" />
        <div class="smallTitle demibold">{{ $t("账户管理") }}</div>
        <p
          class="content regular animate__animated animate__fadeInUpBig"
          id="content4"
        >
          {{ $t("开卡、充值、冻结、注销操作尽在掌控，体验拉满，事事安心") }}!
        </p>
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoPage",
  data() {
    return {
      qudaoShow: false,
      currentLanguage: "zh",
    };
  },
  watch: {
    // qudaoShow(val) {
    //   if (val) {
    //     for (let i = 1; i <= 6; i++) {
    //       this.getFont(
    //         this.$refs[`content${i}`].innerText,
    //         document.getElementById(`content${i}`)
    //       );
    //     }
    //   } else {
    //     for (let i = 1; i <= 6; i++) {
    //       this.removeFont(document.getElementById(`content${i}`));
    //     }
    //   }
    // },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.currentLanguage = localStorage.getItem("accept_language");
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 20, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        // e = dom.offsetHeight; // 元素高度
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
        // if (b > c + e) {
        //   this.qudaoShow = false;
        // }
        // if (b + c < a) {
        //   this.qudaoShow = false;
        // }
      }
    },
    // getFont(text, container) {
    //   const letters = text.split("");
    //   letters.forEach((letter, index) => {
    //     const span = document.createElement("span");
    //     span.classList.add("reveal");
    //     span.textContent = letter;

    //     setTimeout(() => {
    //       container.appendChild(span);
    //       span.classList.add(
    //         "animate__animated",
    //         "animate__fadeInUp",
    //         "animate__faster"
    //       );
    //     }, 100 * index);
    //   });
    // },
    // removeFont(container) {
    //   const children = container.children; // 获取子元素集合
    //   // 转换为数组并反向遍历以避免索引问题
    //   Array.from(children).forEach((child) => child.remove());
    // },
  },
};
</script>

<style lang="scss" scoped>
.twoPage {
  height: 100%;
  background: #131313;
  color: #fff;
  padding: 30px 20px 10px;
  .bigTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 26.52px;
    text-align: center;
  }
  .main {
    margin-top: 30px;
    li {
      list-style: none;
      position: relative;
      overflow: hidden; /* 或者 auto */
      // width: 380px;
      // height: 302px;
      width: 155px;
      min-height: 180px;
      border-radius: 12px;
      border: 3px solid;
      display: inline-block;
      box-sizing: border-box;
      border-image-source: radial-gradient(
        80.79% 50% at 50% 50%,
        #8c4424 0%,
        rgba(140, 68, 36, 0) 100%
      );
      backdrop-filter: blur(38.62782287597656px);
      padding: 16px 23px 37px 12px;
      margin-right: 25px;
      margin-bottom: 15px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:hover {
        transform: scale(1.02); /* 鼠标滑过时放大 */
      }
      img {
        width: 36px;
        height: 36px;
        opacity: 1;
      }
      .smallTitle {
        font-size: 14px;
        line-height: 31.82px;
        text-align: left;
        margin-top: 15px;
        margin-bottom: 6px;
        color: #fff;
        opacity: 1;
      }
      .content {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.91px;
        text-align: left;
        width: 126px;
        height: 48px;
        color: #ffffff;
        opacity: 0.8;
      }
    }
    li::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("@/assets/imgs/bg2-1.png") no-repeat;
      opacity: 0.3;
      background-size: 100% 100%;
      z-index: -1; /* 确保伪元素在内容之下 */
    }
  }
}

// @media (min-width: 1366px) {
//   .main {
//     li {
//       padding: 40px 30px !important;
//       margin-bottom: 10px;
//     }
//   }
// }
</style>
